<template>
  <section>
    <h2>
      Ключевые даты
    </h2>

    <tricolor-line/>
    <div class="key-dates">
      <div class="key-dates__container">
        <key-date-card v-for="(card,index) in getKeyDates" :card="card" :key="`keyDatesCards${index}`"/>
      </div>

      <time-line :dates="getKeyDates"/>
    </div>
  </section>
</template>

<script>
import TricolorLine from '../TricolorLine'
import KeyDateCard from './KeyDateCard'
import TimeLine from './TimeLine'

export default {
  name: 'KeyDatesSection',
  created () {
    this.$store.dispatch('setKeyDates')
  },
  computed: {
    getKeyDates () {
      return this.$store.getters.getKeyDates
    }
  },
  components: {
    TimeLine,
    KeyDateCard,
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  .key-dates__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 2rem;
  }
  @media screen and (max-width: 768px){
    h2{
      width: 100% !important;
    }
    .key-dates{
      display: flex;
      &__container{
        flex-direction: column;
        width: 68%;
        & :nth-child(n){
          width: 100%;
          margin-bottom: 2rem;
        }
        & :last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 420px){
    .key-dates{
      &__container{
        width: 75%;
      }
    }
  }
</style>
