<template>
  <section>
    <h2>
      «Мой проект — моей стране!» — ежегодный конкурс Общественной палаты РФ в области гражданской активности
    </h2>

    <div class="target-materials__container">
      <article class="target__container" :style="`background: url('/assets/bg/bg_my_project_target.png') 88% top / 24.62rem; background-repeat: no-repeat`">
        <div class="text__container">
          <h3>
            Цель конкурса
          </h3>

          <p>
            Выявление и распространение лучших практик, поддержка и вовлечение их авторов в развитие конструктивной гражданской активности в России
          </p>
        </div>

        <!-- <img src="/assets/bg/bg_my_project_target.png" alt=""> -->
      </article>

      <div class="materials__container">
        <h4>
          Дополнительные материалы
        </h4>

        <material v-for="(material,index) in getAdditionalMaterial" :material="material" :key="`material${index}`"/>
      </div>
    </div>
  </section>
</template>

<script>
import Material from './Material'

export default {
  name: 'CompetitionTargetSection',

  data () {
    return {
      materials: [
        {
          title: 'Описание номинаций',
          url: '/',
          size: 217,
          type: 'PDF',
          icon: 'achievement.svg'
        },
        {
          title: 'Положение',
          url: '/',
          size: 217,
          type: 'PDF',
          icon: 'document_icon.svg'
        },
        {
          title: 'Критерии оценки',
          url: '/',
          size: 217,
          type: 'PDF',
          icon: 'graph.svg'
        },
        {
          title: 'Часто задаваемые вопросы',
          url: '/',
          size: 217,
          type: 'PDF',
          icon: 'question_blue.svg'
        }
      ]
    }
  },

  created () {
    this.$store.dispatch('setAdditionalMaterial')
  },

  computed: {
    getAdditionalMaterial () {
      return this.$store.getters.getAdditionalMaterial
    }
  },

  components: {
    Material
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .target-materials__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > .target__container {
      display: flex;
      width: 55.375rem;
      background-color: white;
      border: .06rem solid #E5E8EE;
      box-sizing: border-box;
      box-shadow: 0 .937rem 1.562rem rgba(189, 205, 221, 0.2);
      border-radius: .5rem;
      padding: 0 4.81rem 2rem 3.125rem;

      > .text__container {
        width: 40%;

        h3 {
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: $primary-color;
          margin-bottom: 1.125rem;
          margin-top: 3.125rem;
        }
        p {
          font-size: .88rem;
          line-height: 1.38rem;
          color: rgba(23, 23, 23, 0.8);
        }
      }
    }

    > .materials__container {
      display: flex;
      flex-direction: column;
      width: 26.56rem;

      > h4 {
        font-weight: 600;
        font-size: .81rem;
        line-height: 1rem;
        text-transform: uppercase;
        color: $mainFontColor;
        margin-bottom: 1.375rem;
      }

      > ::v-deep .wrapper {
        margin-bottom: 1.5rem;
      }
    }
  }
  @media screen and (max-width: 768px){
    h2{
      width: 100% !important;
    }
    .target-materials__container {
      flex-direction: column;

      >.target__container{
        background-size: contain !important;
        width: 100%;
        margin-bottom: 2rem;
      }
      .materials__container{
          width: 100%;
        }
    }
  }
  @media screen and (max-width: 420px){
    .target-materials__container {
      >.target__container{
        background: #ffffff !important;
        > .text__container {
          width: 100%;
        }
      }
    }
  }
</style>
