<template>
  <article>
    <h6>
      <span>
        {{ card.date }}
      </span>
      {{ card.month }}
    </h6>

    <p>
      {{ card.title }}
    </p>
  </article>
</template>

<script>
export default {
  name: 'KeyDateCard',
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars.scss";

  article {
    width: 15rem;
    height: 9.375rem;

    background-color: white;
    border: .06rem solid #E5E8EE;
    box-sizing: border-box;
    border-radius: .5rem;
    padding: 1.25rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    transition: .35s;

    > h6 {
      font-weight: 600;
      font-size: .81rem;
      line-height: 1rem;
      text-transform: uppercase;
      color: $primary-color;

      span {
        font-size: 2.25rem;
        line-height: 2.75rem;
        letter-spacing: 0.5px;
        font-weight: bold;
      }
    }

    > p {
      text-align: center;
      font-size: .875rem;
      line-height: 1.375rem;
      color: #000000;
      width: 90%;
    }

    &:hover {
      box-shadow: 0 .93rem 1.562rem rgba(189, 205, 221, 0.2);
    }
  }
</style>
