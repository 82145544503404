<template>
  <section>
    <h2>
      Номинации
    </h2>

    <tricolor-line/>

    <div class="nominations__container">
      <nomination v-for="(nomination,index) in setNomination" :key="`nominationCard${index}`" :card="nomination"/>
    </div>
  </section>
</template>

<script>
import TricolorLine from '../TricolorLine'
import Nomination from './Nomination'

export default {
  name: 'NominationsSection',
  created () {
    this.$store.dispatch('setNomination')
  },
  computed: {
    setNomination () {
      return this.$store.getters.getNomination
    }
  },
  components: {
    Nomination,
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  .nominations__container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 2rem;
    margin-bottom: -2.1875rem;

    > * {
      margin-right: 2.1875rem;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
  @media screen and (max-width: 768px){
    h2{
      width: 100% !important;
    }
    .nominations__container{
      > * {
        width: 100%;
        &:nth-child(n) {
          margin-right: 0;
        }
      }
    }
  }
</style>
