<template>
  <article @click="togglePopup">
    <img :src="card.image" alt="">
    <h6>
      {{ card.title }}
    </h6>

    <transition-group name="fade" mode="out-in">
      <div class="backdrop" v-if="isOpen" key="popupBackdrop"></div>

      <div class="popup__block" v-if="isOpen" @click="togglePopup" key="popup">
        <div class="close__button" @click="togglePopup">
          <img src="/assets/icons/close.svg">
        </div>

        <div class="title">
          <img :src="card.image" alt="">

          <h5>
            {{ card.title }}
          </h5>
        </div>

        <p>
          {{ card.description }}
        </p>

        <router-link to="/project-submit-appeal" class="btn-blue">
          Подать заявку
        </router-link>
      </div>
    </transition-group>
  </article>
</template>

<script>
export default {
  name: 'Nomination',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    togglePopup () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  article {
    width: 19.37rem;
    height: 5.31rem;

    background-color: white;
    border: .06rem solid #E5E8EE;
    box-sizing: border-box;
    border-radius: .5rem;

    padding: 1.25rem;
    margin-bottom: 2.1875rem;

    display: flex;
    align-items: center;

    cursor: pointer;

    > img, .popup__block > .title > img {
      width: 2.81rem;
      height: 2.81rem;
      object-fit: contain;
      object-position: center;
      margin-right: .75rem;
      border-radius: 50%;
    }

    > h6 {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
      width: calc(100% - 3.7rem);
    }

    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $mainFontColor;
      opacity: .33;
      z-index: 50;
    }

    .popup__block {
      cursor: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: white;
      box-sizing: border-box;
      padding: 2.187rem;
      width: 26.8rem;
      z-index: 75;
      border-radius: .18rem;

      > .close__button {
        position: absolute;
        height: 2.125rem;
        width: 2.312rem;
        background-color: white;
        box-shadow: 0 .562rem .93rem rgba(189, 205, 221, 0.35);
        border-radius: 4.187rem;
        transform: matrix(0, 1, -1, 0.02, 0, 0) rotate(90deg);
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        top: -1.075rem;
        right: -1.150rem;
      }

      > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: .75rem;
        border-bottom: .06rem solid #EEF4FA;

        > h5 {
          font-weight: bold;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: $mainFontColor;
          width: calc(100% - 3.7rem);
        }
      }

      > p {
        margin-bottom: 2rem;
        font-size: .88rem;
        line-height: 1.5rem;
      }

      > .btn-blue {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 768px){
    article .popup__block {
      width: 89%;
    }
  }
</style>
