<template>
  <article class="principle__card">
    <div class="number__image">
      <span>{{ index }}</span>
    </div>

    <p>{{ principle.title }}</p>
    <p>{{ principle.description }}</p>
  </article>
</template>

<script>
export default {
  name: 'PrincipleCard',
  props: {
    index: {
      type: Number,
      required: false,
      default: 0
    },
    principle: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .principle__card {
    width: 19.375rem;
    min-height: 13.81rem;
    background-color: white;
    border: .06rem solid #E5E8EE;
    box-sizing: border-box;
    box-shadow: 0 .93rem 1.56rem rgba(189, 205, 221, 0.2);
    border-radius: .5rem;
    padding: 1.18rem 1.875rem 1.875rem 1.18rem;
    margin-bottom: 2rem;

    > .number__image {
      height: 5.5rem;
      width: 5.5rem;
      background-image: url('../../assets/icons/circle_with_lines.svg');
      background-size: contain;
      object-fit: contain;
      object-position: bottom right;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.25rem;

      > span {
        font-weight: bold;
        font-size: 1.375rem;
        line-height: 2rem;
        color: $primary-color;
      }
    }

    > p {
      padding-left: .68rem;
      font-size: .87rem;
      line-height: 1.375rem;

      &:first-of-type {
        font-weight: 600;
        color: $mainFontColor;
        margin-bottom: .375rem;
      }

      &:last-of-type {
        font-weight: normal;
        color: rgba(23, 23, 23, 0.8);
        width: 12rem;
      }
    }
  }
  @media screen and (max-width: 420px){
    .principle__card {
      width: 100%;
    }
  }
</style>
