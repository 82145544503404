<template>
  <article :style="openedCardStyle">
    <div class="card__title" @click="toggleOpen()">
      <h6>
        {{ card.title }}
      </h6>

      <img src="/assets/icons/arrow_grey.svg" :style="arrowStyle">
    </div>

    <div class="card__content" v-show-slide="isOpen">
      <div class="content" v-html="card.text" ></div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'SlidingInformationCard',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
    }
  },
  computed: {
    openedCardStyle () {
      return {
        boxShadow: this.isOpen ? '0 .93rem 1.56rem rgba(189, 205, 221, 0.35)' : 'none'
      }
    },
    arrowStyle () {
      return {
        transform: `rotate(${this.isOpen ? 180 : 0}deg)`
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/utils/vars";

  article {
    background-color: white;
    border: .06rem solid #E5E8EE;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.25rem 1.875rem;
    display: flex;
    flex-direction: column;
    transition: .35s;

    > .card__title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
      > h6 {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.375rem;
        color: $mainFontColor;
        width: 42.81rem;
      }

      img {
        width: .75rem;
        transition: .35s;
      }
    }

    > .card__content > .content{
      padding-top: 1.25rem;
      margin-top: 1.25rem;
      border-top: .06rem solid #E5E5E5;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $tagFontColor;

      &::v-deep {
        ul {
          margin-top: .5rem;

          > li {
            margin-top: 0.75rem;
            padding-left: 1rem;
            position: relative;

            /* TODO : delete this bullshit list marks */

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: calc(50% - .25rem);
              height: .25rem;
              width: .25rem;
              background-color: $primary-color;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
</style>
