<template>
  <div class="wrapper">
    <div class="img-background">
      <img :src="material.icon">
    </div>

    <div class="content__container">
      <p>{{ material.title }}</p>

      <a :href="material.link">
        {{ material.link_title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Material',
  props: {
    material: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .wrapper {
    display: flex;

    > .img-background {
      width: 2.81rem;
      height: 2.81rem;
      border-radius: 50%;
      background-color: #E8F2FC;
      margin-right: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        max-height: 1.56rem;
        max-width: 1.56rem;
        object-position: center;
        object-fit: contain;
      }
    }

    > .content__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > p {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.375rem;
        color: $mainFontColor;
      }

      > a {
        font-style: normal;
        font-weight: normal;
        font-size: .75rem;
        line-height: 1.125rem;
        color: $primary-color;
      }
    }
  }
</style>
