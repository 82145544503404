<template>
  <section>
    <h2>
      Основная информация
    </h2>

    <div class="information__wrapper">
      <div class="cards__container">
        <sliding-information-card v-for="(card,index) in getMainInformation" :card="card" :key="`informationCard${index}`"/>
      </div>

      <submit-appeal-card class="submit-appeal-card"/>
    </div>
  </section>
</template>

<script>
import SlidingInformationCard from '../SlidingInformationCard'
import SubmitAppealCard from './SubmitAppealCard'

export default {
  name: 'InformationSection',

  data () {
    return {
      informationCards: [
        {
          title: 'Кто может участвовать?',
          content: `Все, кто работает на создание сильного гражданского общества в России:
          <ul>
            <li>Руководители социальных проектов</li>
            <li>Социально ответственные компании</li>
            <li>Социально активные граждане</li>
            <li>Лидеры некоммерческих организаций</li>
          </ul>`
        },
        {
          title: 'Как подать заявку?',
          content: `Все, кто работает на создание сильного гражданского общества в России:
          <ul>
            <li>Руководители социальных проектов</li>
            <li>Социально ответственные компании</li>
            <li>Социально активные граждане</li>
            <li>Лидеры некоммерческих организаций</li>
          </ul>`
        },
        {
          title: 'Что получает победитель?',
          content: `Все, кто работает на создание сильного гражданского общества в России:
          <ul>
            <li>Руководители социальных проектов</li>
            <li>Социально ответственные компании</li>
            <li>Социально активные граждане</li>
            <li>Лидеры некоммерческих организаций</li>
          </ul>`
        },
        {
          title: 'Кто будет оценивать?',
          content: `Все, кто работает на создание сильного гражданского общества в России:
          <ul>
            <li>Руководители социальных проектов</li>
            <li>Социально ответственные компании</li>
            <li>Социально активные граждане</li>
            <li>Лидеры некоммерческих организаций</li>
          </ul>`
        },
        {
          title: 'Что будут оценивать?',
          content: `Все, кто работает на создание сильного гражданского общества в России:
          <ul>
            <li>Руководители социальных проектов</li>
            <li>Социально ответственные компании</li>
            <li>Социально активные граждане</li>
            <li>Лидеры некоммерческих организаций</li>
          </ul>`
        }
      ]
    }
  },

  created () {
    this.$store.dispatch('setMainInformation')
  },

  computed: {
    getMainInformation () {
      return this.$store.getters.getMainInformation
    }
  },

  components: {
    SubmitAppealCard,
    SlidingInformationCard
  }
}
</script>

<style scoped lang="scss">

  .information__wrapper {
    display: flex;
    justify-content: space-between;

    .cards__container {
      width: 55.31rem;
      display: flex;
      flex-direction: column;

      > * {
        margin-bottom: 1rem;
      }
    }
  }
  @media screen and (max-width: 768px){
    h2{
      width: 100% !important;
    }
    .information__wrapper{
      flex-direction: column;
      .cards__container {
        width: 100%;
      }
      .submit-appeal-card{
        width: 100%;
      }
    }
  }
</style>
