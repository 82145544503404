<template>
  <section>
    <h2>
      Принципы конкурса
    </h2>

    <div class="principles__container">
      <principle-card v-for="(principle,index) in getProjectPrinciple" :principle="principle" :key="`principles${index}`" :index="index + 1"/>
    </div>
  </section>
</template>

<script>
import PrincipleCard from './PrincipleCard'

export default {
  name: 'CompetitionPrinciplesSection',

  data () {
    return {
      principles: [
        {
          title: 'Доступность',
          text: 'Любой может подать заявку'
        },
        {
          title: 'Разнообразие',
          text: 'Широкий спектр представленных направлений деятельности'
        },
        {
          title: 'Уникальность',
          text: 'Находимся в поиске тех проектов, которые еще никто не реализовывал'
        },
        {
          title: 'Прозрачность',
          text: 'Отсутствие скрытых критериев оценки'
        }
      ]
    }
  },

  created () {
    this.$store.dispatch('setProjectPrinciple')
  },

  computed: {
    getProjectPrinciple () {
      return this.$store.getters.getProjectPrinciple
    }
  },

  components: {
    PrincipleCard
  }
}
</script>

<style scoped lang="scss">

  .principles__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 768px){
    h2{
      width: 100% !important;
    }
    .principles__container {
      margin-bottom: -2rem;
    }
  }
</style>
