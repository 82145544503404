<template>
  <section>
    <h2>
      Контакты
    </h2>

    <tricolor-line/>

    <div class="contacts__container">
      <article>
        <span class="label">
          Телефон горячей линии ОП РФ <br> (звонок бесплатный)
        </span>

        <a href="tel:8 800 737 77 66">
          <p>
            8 800 737 77 66
          </p>
        </a>
      </article>

      <article>
        <span class="label">
          E-mail
        </span>

        <a :href="`mailto:${projectEmail.email}`">
          <p>
            {{ projectEmail.email }}
            <!--proektstrane@gmail.com-->
          </p>
        </a>

        <span class="label soc_icons">
          Социальные сети

          <a href="https://vk.com/oprf_official" class="contacts__icon">
            <img src="@/assets/icons/vk.svg">
          </a>
<!--          <a href="https://www.instagram.com/proektstrane/" class="contacts__icon">
            <img src="@/assets/icons/instagram.svg">
          </a>
          <a href="https://www.facebook.com/CivicChamber/" class="contacts__icon">
            <img src="@/assets/icons/facebook.svg">
          </a>-->
        </span>
      </article>

      <article>
        <span class="label">
          Организатор
        </span>

        <a href="/">
          <p>
            Общественная палата Российской Федерации
          </p>
        </a>
      </article>

      <article>
        <span class="label">
          Время работы
        </span>

        <p>
          Пн – Чт с 09:00 до 18:00<br/>
          Пт – с 09:00 до 16:45
         </p>
      </article>
    </div>

    <!--<iframe class="map-frame" src="https://yandex.ru/map-widget/v1/?um=constructor%3Afce6d80c3fb8fbf4dac4c45193f7059396256ae3e2a0094c76f737cf143ca370&amp;source=constructor" frameborder="0"></iframe>-->
  </section>
</template>

<script>
import TricolorLine from '../TricolorLine'

export default {
  name: 'ContactsSection',

  data () {
    return {
      projectEmail: {
        email: null
      }
    }
  },

  created () {
    this.getProjectEmail()
  },

  methods: {
    async getProjectEmail () {
      let res = await fetch(`${this.$store.getters.getUrlApi}api/project_email`)
      res = await res.json()
      this.projectEmail = await res
    }
  },

  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .soc_icons {
    display: flex;
    flex-direction: row;
    align-items: center;

    a:first-of-type {
      margin-left: 1rem;
    }
  }

  .contacts__icon {
    width: 2rem;
    height: 2rem;
    background: #EEF4FA;
    border-radius: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: .5rem;

    img {
      height: 1rem;
      width: 1rem;
    }
  }

  section > p {
    margin: 1.937rem 0 1.375rem;
    font-size: .875rem;
    line-height: 1.375rem;
    color: rgba(23, 23, 23, 0.8);
  }

  .contacts__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 2rem;

    > article {
      width: 19.375rem;
      display: flex;
      flex-direction: column;

      > .label {
        font-size: .75rem;
        line-height: 1.125rem;
        color: rgba(23, 23, 23, 0.8);
        opacity: .8;
        margin-bottom: .437rem;
      }

      a {
        color: $primary-color;
      }

      p {
        font-size: .875rem;
        line-height: 1.375rem;
      }
    }
  }

  .map-frame{
    max-width:initial;
    width:100%;
    min-height:22rem;
    height:100%;
    margin-top: 2rem;
  }
  @media screen and (max-width: 768px){
    h2{
      width: 100% !important;
    }
  }
</style>
