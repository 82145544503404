<template>
  <section class="title__section" :style="`background: url('/assets/bg/bg_my_project.png') right bottom / contain; background-repeat: no-repeat`">
    <div class="title-buttons__wrapper">
      <h1>
        Мой проект —
        <span>моей стране!</span>
      </h1>
      <p>Конкурс социально значимых проектов</p>
      <div class="button__container">
        <router-link to="/personal-account" class="btn-blue">
          Вход в личный кабинет
        </router-link>

        <a :href="link.link" class="btn-white" v-for="link in getProjectTitleLink" :key="link.title">
          <img src="/assets/icons/download.svg" alt="" class="download-icon">
          {{ link.title }}
        </a>
      </div>
    </div>

    <!-- <img src="/assets/bg/bg_my_project.png" alt="" class="title-img"> -->
  </section>
</template>

<script>
export default {
  name: 'MyProjectTitleSection',

  created () {
    this.$store.dispatch('setProjectTitleLink')
  },

  computed: {
    getProjectTitleLink () {
      return this.$store.getters.getProjectTitleLink
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .title__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2.5rem;
    height: 29.38rem;
    margin-bottom: 3.13rem !important;

    > .title-buttons__wrapper {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 3.437rem;
        line-height: 3.75rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $mainFontColor;
        margin-bottom: .3125rem;

        span {
          color: $primary-color;
          display: block;
        }
      }

      p {
        margin-bottom: 1.875rem;
        font-size: .88rem;
        line-height: 1.38rem;
        color: rgba(23, 23, 23, 0.8);
      }

      .button__container {
        display: flex;

        > .btn-blue {
          margin-right: 1.3125rem;
        }

        .download-icon {
          height: .875rem;
          margin: 0 .5rem;
        }
      }
    }

    > .title-img {
      width: 56.25rem;
      object-fit: contain;
      object-position: right top;
    }
  }
  @media screen and (max-width: 768px){
    .title__section{
      background-position: 11rem bottom !important;

      > .title-buttons__wrapper {
        p {
          width: 80%;
        }
      }
    }
  }
  @media screen and (max-width: 420px){
    .title__section{
      background-position: 8rem bottom !important;
      height: 24.38rem;
      padding: 0 2.5rem;

      > .title-buttons__wrapper {
          h1 {
            font-size: 2.437rem;
          }
          .button__container {
            display: flex;
            flex-direction: column;

            > .btn-blue {
              margin: 0 0 1.313rem 0;
              width: 80%;
            }
            .btn-white{
              width: 80%;
            }
          }
      }
    }
  }
</style>
