<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="white-container my-project__container">
      <my-project-title-section/>

      <div class="container">
        <competition-target-section/>

        <competition-principles-section/>

        <information-section/>
      </div>
    </div>

    <div class="white-container">
      <key-dates-section/>
    </div>

    <div class="white-container">
      <nominations-section/>
    </div>

    <div class="white-container">
      <contacts-section/>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import MyProjectTitleSection from '@/components/myProject/MyProjectTitleSection'
import CompetitionTargetSection from '@/components/myProject/CompetitionTargetSection'
import InformationSection from '@/components/myProject/InformationSection'
import CompetitionPrinciplesSection from '@/components/myProject/CompetitionPrinciplesSection'
import KeyDatesSection from '@/components/myProject/KeyDatesSection'
import NominationsSection from '@/components/myProject/NominationsSection'
import ContactsSection from '@/components/myProject/ContactsSection'

export default {
  name: 'MyProject',
  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Мой проект — моей стране!'
        }
      ]
    }
  },
  mounted () {
    document.title = 'Мой проект — моей стране!'
  },
  components: {
    ContactsSection,
    NominationsSection,
    KeyDatesSection,
    CompetitionPrinciplesSection,
    InformationSection,
    CompetitionTargetSection,
    MyProjectTitleSection,
    BreadCrumb
  }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/scss/utils/vars";

  ::v-deep h2 {
    color: $mainFontColor;
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.75rem;
    width: 45.3rem;
    margin-bottom: 2rem;
  }

  .my-project__container {
    /*padding: 0;*/
    box-sizing: border-box;
    padding: 0 0 2.5rem 0;

    > section, > .container > section {
      margin-bottom: 5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .white-container {
    margin-bottom: 3.125rem;
  }
</style>
