<template>
  <article>
    <img src="/assets/bg/bg_submit_appeal.png">

    <h5>
      {{ getProjectAppealText.title ? getProjectAppealText.title : ''}}
    </h5>

    <p>
      {{ getProjectAppealText.text ? getProjectAppealText.text : ''}}
    </p>

    <a :href="getProjectAppealText.link ? getProjectAppealText.link : '/'" class="btn-blue">
      {{ getProjectAppealText.link_title ? getProjectAppealText.link_title : ''}}
    </a>
  </article>
</template>

<script>
export default {
  name: 'SubmitAppealCard',

  created () {
    this.$store.dispatch('setProjectAppealText')
  },

  computed: {
    getProjectAppealText () {
      return this.$store.getters.getProjectAppealText.length ? this.$store.getters.getProjectAppealText[0] : {
        title: '',
        text: '',
        link: '',
        link_title: ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  article {
    background-color: white;
    border: .06rem solid #E5E8EE;
    box-sizing: border-box;
    box-shadow: 0 .93rem 1.56rem rgba(189, 205, 221, 0.2);
    border-radius: .5rem;

    width: 26.56rem;
    padding: 1.875rem;
    height: 23rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 20.375rem;
      height: 8.43rem;
      object-fit: contain;
      object-position: center;
      margin-bottom: 2.937rem;
    }

    > h5 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: $mainFontColor;
    }

    > p {
      margin: .5rem 0 1.375rem;
      font-size: .75rem;
      line-height: 1.125rem;
      color: $mainFontColor;
    }

    > .btn-blue {
      text-align: center;
    }

    > h5, > p, > .btn-blue {
      width: 100%;
    }
  }
</style>
