<template>
  <div class="time-line__wrapper">
    <div class="point" v-for="(date,index) in dates" :key="`timeLinePoint${index}`">
      <img :src="`/assets/icons/${(index + 1) === dates.length ? 'circle_achievement' : 'circle-circle'}.svg`">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeLine',
  props: {
    dates: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .time-line__wrapper {
    /*padding: 0 6.68rem;*/
    width: 71.43rem;
    margin: 1.375rem auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .point {
      z-index: 2;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: .125rem;
      background-color: #D6E6F5;
    }
  }
  @media screen and (max-width: 768px){
    .time-line__wrapper{
      margin: 5.375rem 0 2rem 5rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: auto;
      &::before {
        content: '';
        top: 0;
        left: 49%;
        width: .125rem;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 768px){
    .time-line__wrapper{
      margin-left: 1.5rem;
    }
  }
</style>
